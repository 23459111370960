<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          We are amazing!
        </h1>

        <p class="subheading font-weight-regular">
          <br /><i>... as well as you are!</i>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>
